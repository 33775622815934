@import './assets/scss/variables';

@font-face {
  font-family: 'panton';
  src: url('../public/assets/fonts/panton-font/Panton-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'panton';
  src: url('../public/assets/fonts/panton-font/Panton-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'panton';
  src: url('../public/assets/fonts/panton-font/Panton-ExtraBold.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Srbija Sans';
  src: url('../public/assets/fonts/SrbijaSans-Regular.eot');
  src: local('Srbija Sans Regular'), local('SrbijaSans-Regular'),
    url('../public/assets/fonts/SrbijaSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/assets/fonts/SrbijaSans-Regular.woff2') format('woff2'),
    url('../public/assets/fonts/SrbijaSans-Regular.woff') format('woff'),
    url('../public/assets/fonts/SrbijaSans-Regular.ttf') format('truetype');
  font-weight: normal;
}


@font-face {
  font-family: 'Raleway';
  src: url('../public/assets/fonts/Raleway-Regular.eot');
  src: local('Raleway'), local('Raleway-Regular'),
    url('../public/assets/fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/assets/fonts/Raleway-Regular.woff2') format('woff2'),
    url('../public/assets/fonts/Raleway-Regular.woff') format('woff'),
    url('../public/assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
}


html,
body {
  height: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;

  @media screen and (max-width: 768px) {
    width: 4px;
  }
}
::-webkit-scrollbar-thumb {
  background: #7359F8;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
  background: #000000;
}

body {
  background: var(--bgPrimary);
  font-size: 0.938rem;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  position: relative;
  // background-image: url(../src/assets/images/bg-image-3.png);
  background-image: url(../src/assets/images/bg-image-4.png);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  // background-position: 115% -111px;
  background-size: cover;

  @media screen and (max-width: 991px) {
    background-image: url(../src/assets/images/bgmob.png);
    background-size: cover;
    // background-attachment: fixed;
  }

  // .container-fluid {
  //   &.custom-container-fluid {
  //     max-width: 1311px;
  //   }
  // }

  table.default-table {
    font-size: 0.813rem;
    border-collapse: separate;
    border-spacing: 0 0.375rem;

    thead {
      tr {
        th {
          font-weight: 500;
          padding: 1rem 1.25rem;
          color: var(--white-7);
          border-width: 0;
          font-family: 'Srbija Sans';
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle;
          border: 0;
          font-family: 'Raleway';
          padding: 0.425rem 0.75rem;
          font-size: 1rem;
          font-weight: 600;

          &:first-child {
            border-radius: 0.5rem 0 0 0.5rem;
          }

          &:last-child {
            border-radius: 0 0.5rem 0.5rem 0;
          }
        }

        &.lost-row {
          td {
            background-color: var(--bgPrimary);
          }
        }
      }
    }

    &.custom-games-table {
      border-spacing: 0 0.675rem;

      thead {
        tr {
          th {
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            padding: 0.25rem;
          }
        }
      }

      tbody {
        tr {
          td {
            background-color: var(--PlinkoSidebarBg);
            border-color: var(--PlinkoSidebarBg);
            font-weight: 600;

            .table-user-icon {
              font-size: 0.813rem;
              width: 36px;
              height: 36px;
              display: inline-block;
              background: var(--plinkoBtnPrimaryHover);
            }

            .table-multiplier-label {
              padding: 0.25rem 0.5rem;
              font-size: 0.813rem;
              min-width: 60px;
            }
          }

          &:nth-child(2n) {
            td {
              background-color: var(--plinkoTableBg);
              border-color: var(--plinkoTableBg);
            }
          }

          // &:nth-child(5n + 1) {
          //   td {
          //     .table-multiplier-label {
          //       background-color: var(--plinkoOrange800);
          //     }
          //   }
          // }

          // &:nth-child(5n + 2) {
          //   td {
          //     .table-multiplier-label {
          //       background-color: var(--plinkoBlue700);
          //     }
          //   }
          // }

          // &:nth-child(5n + 3) {
          //   td {
          //     .table-multiplier-label {
          //       background-color: var(--plinkoBlue300);
          //     }
          //   }
          // }

          // &:nth-child(5n + 4) {
          //   td {
          //     .table-multiplier-label {
          //       background-color: var(--plinkoPurple500);
          //     }
          //   }
          // }

          // &:nth-child(5n + 5) {
          //   td {
          //     .table-multiplier-label {
          //       background-color: var(--plinkoRed800);
          //     }
          //   }
          // }

          .red {
            background-color: #C82020 !important;
          }

          .orange {
            background-color: #FC6F1F !important;
          }

          .pink {
            background-color: #B122F4 !important;
          }

          .purple {
            background-color: #8951FF !important;
          }

          .blue {
            background-color: #346DFF !important;
          }

          .green {
            background-color: #3CBCB4 !important;
          }
        }
      }
    }
  }

  .hr-line {
    border-color: var(--white-4);
    box-shadow: 0 2px 0 var(--black);
  }

  .badge {
    min-height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // bg colors class
  .bg-light-purple {
    background-color: var(--lightPurpleColor) !important;
  }

  .bg-orange {
    background-color: var(--orange) !important;
  }

  .bg-golden {
    background-color: var(--golden) !important;
  }

  .bg-blue-light {
    background-color: var(--lightBlue) !important;
  }

  // text colors class
  .text-light-purple {
    color: var(--lightPurpleColor);
  }

  .text-orange {
    color: var(--orange);
  }

  .text-golden {
    color: var(--golden);
  }

  .text-blue-light {
    color: var(--lightBlue);
  }

  .text-green {
    color: var(--green);
  }

  // common colors

  .plinko-orange-800 {
    background-color: var(--plinkoOrange800) !important;
  }

  .plinko-blue-700 {
    background-color: var(--plinkoBlue700) !important;
  }

  .plinko-blue-300 {
    background-color: var(--plinkoBlue300) !important;
  }

  .plinko-purple-500 {
    background-color: var(--plinkoPurple500) !important;
  }

  .w-18 {
    width: 18px;
    height: 18px;
  }

  .w-20 {
    width: 20px;
    height: 20px;
  }

  .w-24 {
    width: 24px;
    min-height: 24px !important;
    height: 24px;
  }

  .w-26 {
    width: 26px;
    min-height: 26px !important;
    height: 26px;
  }

  .line-height-1-1 {
    line-height: 1.1;
  }

  .flex-1 {
    flex: 1;
  }

  .form-label-18 {
    font-size: 1.125rem;
  }

  .form-control {
    color: var(--white);
    padding: 0.625rem 1rem;
    background-color: var(--bgPrimary);
    border: 2px solid var(--inputBorder);
    padding-left: var(--input-ps, 0.75rem);
    padding-right: var(--input-pe, 0.75rem);

    &:hover {
      border-color: var(--secondary);
    }

    &::placeholder {
      color: var(--white-4);
      font-weight: 500;
    }

    &:focus {
      box-shadow: none;
      background-color: var(--bgPrimary);
      border-color: var(--white);
      color: var(--white);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--white);
      -webkit-box-shadow: 0 0 0px 40rem var(--bgPrimary) inset;
    }

    &:disabled,
    &:disabled:hover {
      background-color: var(--bgPrimary);
      border-color: var(--inputBorder);
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  .custom-input-group {
    .custom-input-group-text {
      gap: 0.375rem;

      .btn-input-addon {
        background-color: var(--primaryHover);
        border: 2px solid var(--primaryHover);
        color: var(--white);
        border-radius: 0.25rem;
        height: 28px;
        padding: 0.125px 0.425rem;
        font-weight: 500;
        font-size: 0.875rem;

        &:is(:active, :hover, .active):enabled {
          background-color: var(--primaryHover);
          border-color: var(--secondary);
        }
      }

      .input-form-control {
        min-height: 30px !important;
        height: 30px !important;
      }
    }
  }

  .form-label {
    color: var(--white);
    font-family: 'Srbija Sans';
  }

  .provably-fair-title h5,
  p.fairness-text {
    font-family: 'Srbija Sans';
  }

  .align-txet-bottom {
    vertical-align: text-bottom !important;
  }

  // vessel modal css
  .vessel-title {
    p {
      font-size: 1.125rem;
      font-weight: 400;
    }
  }

  .vessel-radio-container {
    max-width: 685px;
    margin: auto;

    .vessel-radio-input {
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: 2px solid var(--primary);
      background-origin: border-box;
      margin-top: 0;
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;

      &:checked {
        background-color: var(--greenChecked);
        border-color: transparent;
        background-size: 80%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;

        &+.vessel-radio-btn {
          border-color: var(--greenChecked);
          box-shadow: 0px 0px 12px var(--secondary);
        }
      }
    }

    .vessel-radio-btn {
      width: 180px;
      height: 180px;
      background-color: var(--bgPrimary);
      border: 3px solid var(--primary);
      border-radius: 0.625rem;
      background-image: url('./assets/images/vessel-checked-img.png');
      background-repeat: no-repeat;
      background-size: 146px;
      background-position: bottom left;

      @media only screen and (max-width: 768px) {
        width: 145px;
        height: 145px;

        img {
          max-width: 60%;
        }
      }

      &:hover {
        border-color: var(--secondary);
      }
    }

    .btn-start-playing {
      padding: 0.672rem 2.5rem;
      font-weight: 600;
    }
  }

  .btn-close-modal {
    width: 26px;
    height: 26px;
    line-height: 5px;
    border-radius: 50em;
  }

  .form-switch .form-check-input {
    width: 42px;
    height: 24px;
  }

  .form-switch {
    .form-check-input[type=checkbox] {
      cursor: pointer;
      background-color: var(--tableRowBg);
      border-color: var(--tableRowBg);

      &:not(:checked) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(255, 255, 255, 0.25)'/%3E%3C/svg%3E") !important;
      }

      &:checked {
        background-color: var(--greenChecked);
        border-color: var(--greenChecked);
      }

      &:not(:checked):focus {
        background-color: var(--tableRowBg);
        border-color: var(--tableRowBg);
        box-shadow: none;
      }
    }
  }

  .inset-0 {
    inset: 0 !important;
  }

  .bottom-auto {
    bottom: auto !important;
  }

  .mb-20 {
    margin-bottom: 1.25rem !important;
  }

  .gap-10 {
    gap: 0.625rem;
  }

  .gap-05 {
    gap: 0.313rem;
  }

  .rounded-10 {
    border-radius: 0.613rem !important;
  }

  @media only screen and (min-width: 768px) {
    .gap-md-10 {
      gap: 0.625rem;
    }

    .mw-md-150 {
      max-width: 150px;
    }
  }

  .z-index-1 {
    z-index: 1;
  }

  .text-muted {
    color: var(--white-4) !important;
  }

  @media only screen and (min-width: 992px) {
    .position-lg-absolute {
      position: absolute !important;
    }

    .mb-lg-20 {
      margin-bottom: 1.25rem !important;
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

// .Toastify__toast-container--top-right {
//   top: 12em !important;
//   right: 0 !important;

//   .Toastify__toast {
//     border-radius: 15px 0 0 15px;
//   }

//   .Toastify__toast--error {
//     background: var(--toasterBg) !important;

//     .Toastify__toast-icon {
//       svg {
//         fill: var(--toasterBg) !important;
//       }
//     }
//   }

//   .Toastify__toast--success {
//     background: var(--lightBlue) !important;

//     .Toastify__toast-icon {
//       svg {
//         fill: var(--lightBlue) !important;
//       }
//     }
//   }

//   .Toastify__close-button {
//     min-width: 26px;
//     height: 26px;
//     background: var(--purpleCircleBtn) !important;
//     border-radius: 50em;
//     line-height: 10px;
//     border: 2px solid var(--primaryHover) !important;
//     align-self: center;
//     opacity: 1;
//     padding-left: 1px;
//   }

//   .Toastify__toast-icon {
//     border-radius: 50em;
//     border: 2px solid var(--white);
//     min-width: 24px;
//     background: var(--white);
//   }

//   .Toastify__toast-body {
//     font-weight: 600;
//   }

//   .Toastify__progress-bar {
//     opacity: 0.5;
//   }
// }

// @media only screen and (max-width: 480px) {
//   .Toastify__toast-container {
//     width: 55vw !important;
//     left: auto !important;

//     .Toastify__toast {
//       min-height: 40px;
//       padding: 0.2rem;
//     }

//     .Toastify__toast-body {
//       font-size: 0.75rem;
//     }

//     .Toastify__close-button {
//       min-width: 19px;
//       height: 20px;
//     }

//     .Toastify__toast-icon {
//       min-width: 20px;
//     }
//   }
// }

.loader-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center
}

.cursor-pointer {
  cursor: pointer;
}
