@mixin SecondHeaderButton {
  border-radius: 0.49138rem;
  border: 0.538px solid rgba(255, 255, 255, 0.60);
  background: linear-gradient(84deg, rgba(9, 3, 41, 0.39) 5.83%, rgba(9, 3, 41, 0.39) 65.54%, rgba(9, 3, 41, 0.00) 132.77%), linear-gradient(175deg, rgba(255, 255, 255, 0.05) 3.19%, rgba(255, 255, 255, 0.02) 97.21%);
  backdrop-filter: blur(1.545472264289856px);
  color: var(--white);
  font-family: Raleway;
  font-size: 0.81844rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0.3rem 0.9rem;

}

.secondRoot,
.custom-container-fluid {
  height: 99.9dvh;

  @media screen and (min-width: 991px) and (max-width: 1199px) {
    height: 100%;
  }
}

.muted {
  background-color: var(--crashTabsBg) !important;

  svg {
    fill: var(--plinkoMusicMuted);
  }

  &::before {
    background-color: var(--plinkoMusicMuted);
  }
}




header {
  @media screen and (max-width: 1024px) {
    background-color: transparent !important;
  }

  position: relative;
  z-index: 1002;
  border-bottom: 0.949px solid rgba(227, 232, 252, 0.60);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
  radial-gradient(158.48% 294.91% at 50% 320.96%, rgba(6, 214, 160, 0.20) 0%, rgba(6, 214, 160, 0.00) 100%);
  box-shadow: 0px 46px 85px 0px rgba(0, 0, 0, 0.20),
  0px 43.632px 80.624px 0px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(14.227704048156738px);

  .claim-btn {
    button {
      border-radius: 0.9375rem;
      border: 2px solid #06D6A0;
      box-shadow: 0px 46px 85px 0px rgba(0, 0, 0, 0.20);
      background: radial-gradient(158.48% 294.91% at 50% 320.96%, rgba(6, 214, 160, 0.20) 0%, rgba(6, 214, 160, 0.00) 100%);
      backdrop-filter: blur(15px);
      color: #E3E8FC;
      text-align: center;
      font-family: "Srbija Sans";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      padding: 1.25rem 1.3rem;
    }
  }

  .wallet-btn {
    button {
      padding: 1rem 1.2rem;
      border-radius: 0.98381rem;
      background: var(--white);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
      color: var(--DARK-BLUE, #090329);
      text-align: center;
      font-family: "Srbija Sans";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      border: none;
    }
  }

  .divider {
    @media screen and (max-width: 1024px) {
      display: none;
    }

    background: linear-gradient(90deg, rgba(227, 232, 252, 0.30) 4.21%, rgba(227, 232, 252, 0.10) 98.79%);
    box-shadow: 0px 43.632px 80.624px 0px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(14.227704048156738px);
    height: 2.86rem;
    width: 1px;
    margin: 0 2.7rem
  }

  .navigation-link {
    @media screen and (max-width: 1024px) {
      display: none;
    }

    ul {
      gap: 2.5rem;

      li {
        a {
          color: var(--white);
          text-align: center;
          font-size: 1.0625rem;
          font-weight: 400;
          line-height: 120%;
          text-transform: uppercase;
        }
      }
    }
  }

  .right-navigation {
    @media screen and (max-width: 1024px) {
      display: none;
    }

    ul {
      gap: 1rem;

      li {
        a {
          color: #858291;
          font-family: Raleway;
          font-size: 1.0625rem;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          text-transform: uppercase;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    position: sticky;
    top: 0;
    background-color: var(--bgPrimary);
  }

  nav {
    padding: 1.125rem 0 !important;

    @media screen and (max-width: 767px) {
      padding: 0.25rem 0 !important;
    }

    .navbar-brand {
      cursor: pointer;

      img {
        filter: drop-shadow(0px 0px 10px rgba(181, 25, 255, 0.302));

        @media screen and (max-width: 767px) {
          max-width: 100px;
        }
      }
    }

    .navbar-wallet-btn {
      min-width: 204px;

      @media screen and (max-width: 767px) {
        min-width: unset;
        width: 100%;
      }
    }

    .btn {
      min-height: 42px;
    }

    .setting-dropdown {
      .dropdown-menu {
        background-color: var(--dropdownMenuBg);
        outline: 2px solid var(--purpleColor-3);
        outline-offset: -2px;
        min-width: 280px;
        border-radius: 1rem;
        padding: 1rem;
        z-index: 1002;

        &.show {
          animation: 500ms ease-in slidein;

          @media screen and (max-width: 767px) {
            position: fixed;
            right: 0;
            left: 0;
            top: 50px;
            max-width: 310px;
            margin: auto;
            animation: none;
          }
        }

        .switch-settings {
          background-color: var(--flipCoinGrey400);
          padding: 0 1rem;
          border-radius: 0.625rem;
          list-style: none;

          li {
            padding: 0 !important;

            &:last-child {
              .dropdown-item {
                border-bottom: 0;
              }
            }
          }
        }

        li {
          &:not(:last-child) {
            .dropdown-item {
              border-bottom: 1px solid var(--flipCoinGrey350);
            }
          }

          .dropdown-item {
            padding: 0.75rem 0;
            color: var(--white);
            font-weight: 500;

            &:hover,
            &:focus,
            &:visited {
              background-color: transparent;
              color: var(--white);
              outline: 0;

              svg {
                stroke: var(--white) !important;
              }
            }

            .form-check-input[type=checkbox] {
              background-color: var(--flipCoinHeaderBg);
              border-color: var(--flipCoinHeaderBg);

              &:checked {
                background-color: var(--greenChecked);
                border-color: var(--greenChecked);
              }
            }
          }

          &:not(:first-child) {
            padding: 0 0.8rem;
          }
        }
      }

      & [aria-expanded=false]+.dropdown-menu {
        @media screen and (min-width: 767px) {
          animation: 500ms ease-out slideout;
        }
      }

      .setting-user-icon {
        width: 3rem;
        height: 3rem;
        border-radius: 50em;
        color: var(--white);
        font-weight: 700;
        background-color: var(--userIconBg);
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn-close-modal {
        margin-top: -12px;
        margin-right: -6px;
      }
    }

    .navbar-nav {
      .nav-item {
        &:first-child {
          @media screen and (max-width: 767px) {
            min-width: 100px;
            max-width: 167px;
            width: 100%;
          }
        }
      }
    }
  }

  &.plinko-game-header {
    background-color: transparent;

    nav {
      padding-top: 0.6rem !important;
      padding-bottom: 0.6rem !important;
      border-bottom: 1px solid var(--white-1);
      font-family: 'panton', sans-serif;

      @media screen and (min-width: 992px) {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
      }

      .btn {
        min-width: 42px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        background: var(--plinkoHeaderBtnBg);
        border-color: var(--plinkoHeaderBtnBg);

        &:hover {
          border-color: var(--borderPinkColor) !important;
          background: var(--plinkoHeaderBtnBg) !important;
        }

        &.navbar-wallet-btn {
          min-width: 100px;
          max-width: 200px;
          padding-right: 0.625rem;
          padding-left: 0.625rem;
          font-size: 0.875rem;

          @media screen and (max-width: 374.98px) {
            font-size: 0.75rem;

            .navbar-wallet-currency-icon {
              display: none;
            }
          }

          @media screen and (min-width: 768px) {
            font-size: 1rem;
            min-width: 185px;
          }
        }

        &.navbar-music-btn {
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            top: 6px;
            right: 6px;
            background-color: var(--green);
          }

          &.muted {
            svg {
              fill: var(--plinkoMusicMuted);
            }

            &::before {
              background-color: var(--plinkoMusicMuted);
            }
          }
        }

        &.btn-close-modal {
          min-width: 26px;
          width: 26px;
          height: 26px;
          border-color: var(--plinkoSelectBorder);
        }
      }

      .navbar-wallet-btn-item {
        @media screen and (max-width: 767.98px) {
          flex-grow: 1 !important;
        }
      }

      .setting-dropdown {
        .setting-user-icon {
          border-radius: .625rem;
          background: var(--plinkoBtnPrimaryHover);
          background-origin: border-box;
        }

        .setting-user-name {
          font-weight: 600;
        }

        .dropdown-menu {
          background: var(--PlinkoSidebarBg);
          outline: 2px solid var(--plinkoSelectBorder);
        }
      }

    }

    .navbar-nav {
      .nav-item {
        &:first-child {
          @media screen and (max-width: 767px) {
            min-width: 100px;
            max-width: unset;
            width: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {

  body:has(.setting-dropdown .dropdown-menu.show) #root,
  body:has(.setting-dropdown .dropdown-menu.show) header {
    &:after {
      content: '';
      background-color: var(--black-8);
      position: fixed;
      inset: 0;
      z-index: 888;
    }
  }

  body:has(.setting-dropdown .dropdown-menu.show) header {
    &:after {
      bottom: auto;
      height: 66px;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideout {
  0% {
    transform: translateX(-80%);
    display: block;
    opacity: 1;
  }

  70% {
    transform: translateX(20%);
    display: none;
    opacity: 0;
  }
}


.second-header {
  border-bottom: 1px solid rgba(227, 232, 252, 0.60);
  // opacity: 0.13;
  background: linear-gradient(142deg, rgba(255, 255, 255, 0.13) 11.82%, rgba(255, 255, 255, 0) 60.6%), radial-gradient(573.54% 573.54% at 50% 573.63%, #7359F8 0%, rgba(115, 89, 248, 0) 100%);
  box-shadow: 0px 46px 85px 0px rgba(0, 0, 0, 0.20), 0px 46px 85px 0px rgba(0, 0, 0, 0.20), 0px 46px 85px 0px rgba(0, 0, 0, 0.20), 0px 43.632px 80.624px 0px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(14.227704048156738px);
  padding: 0.8rem 3rem;

  .audited-btn {
    @include SecondHeaderButton;

    button {
      border: none;
      background: transparent;
    }
  }

  .secured-btn {
    @include SecondHeaderButton;

    button {
      border: none;
      background: transparent;
    }
  }
}
