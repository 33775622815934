.footer-section {
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  padding: 1rem 3rem;
  border-top: 1px solid rgba(227, 232, 252, 0.60);
  background: linear-gradient(0deg, rgba(9, 3, 41, 0.20) 0%, rgba(9, 3, 41, 0.20) 100%),
  radial-gradient(158.48% 294.91% at 50% 320.96%, rgba(6, 214, 160, 0.20) 0%, rgba(6, 214, 160, 0.00) 100%);
  box-shadow: 0px 46px 85px 0px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(29.5px);

  .copyright {
    color: var(--white);
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    padding-left: 1rem;

    @media screen and (max-width: 768px) {
      padding-left: 0rem;
    }
  }

  .footer-link {
    li {
      a {
        color: var(--white);
        text-align: right;
        font-family: Raleway;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;

        @media screen and (max-width: 768px) {
          font-size: 0.75rem;
        }
      }

      p {
        @media screen and (max-width: 768px) {
          font-size: 0.75rem;
          color: #858291;
        }
      }
    }
  }
}
